import { defineStore } from 'pinia'
import { useWebNotification } from '@vueuse/core'
import { getUserInfoFn, getUserDetailsFn, getUserSimpleListFn } from '@/api/auth/users'
import { useRuntimeConfig } from '#app'

import { refuseTransferFn } from '@/api/message/conversations'
// import jwtDecode from 'jwt-decode' 目前沒用到

const config = useRuntimeConfig()

export const useUserInfo = defineStore('userInfo', {
  state: () => ({
    state: '', // 頁面狀態
    userInfoData: [], // 個人資訊
    userInfoDetails: [], // 用戶詳情 (含tenant、user、tenantAgentStatus)
    agentUsersList: [], // 客服列表
    agentUsersMap: new Map(), // 客服列表map
    domain: `${config.public.ossUrl}`,
    agentStatus: null, // 當前租戶客服狀態
    twoFactorInfo: null,
    isFirstLogin: null,
    showHomeMain: false,

    transferIds: new Set(), // 轉接去重
    transferList: [] // 轉接
  }),
  getters: {
    // ***
  },
  actions: {
    clean () {
      this.$reset()
    },
    setToken (token) {
      localStorage.setItem('token', token)
    },
    setTenantId (tenantId) {
      localStorage.setItem('tenantId', tenantId)
    },
    // 獲取個人資訊
    async getUserInfo () {
      if (this.userInfoData.length > 0) { return this.userInfoData }
      const res = await getUserInfoFn()

      const data = res.data.value
      if (data.code === 200) {
        this.userInfoData = data.data

        const tenantId = data.data.lastTenantLogin
        this.setTenantId(tenantId)
      }
      return this.userInfoData
    },
    // 獲取用戶詳情
    async getUserDetails () {
      if (this.userInfoDetails.length > 0) { return this.userInfoDetails }
      const res = await getUserDetailsFn()
      const data = res.data.value
      if (data.code === 200) {
        this.userInfoDetails = data.data

        // 判斷第一次登入
        this.isFirstLogin = data.data.tenant.isFirstLogin

        // 有用戶詳情才顯示
        if (this.userInfoDetails && !this.isFirstLogin) {
          this.showHomeMain = true
        }

        // 儲存座席狀態
        this.agentStatus = data.data.tenantAgentStatus.status
      }
      return this.userInfoDetails
    },
    // 獲取用戶列表
    async getAgentUsersList () {
      if (this.agentUsersList.length > 0) { return this.agentUsersList }
      const res = await getUserSimpleListFn()
      const data = res.data.value
      if (data.code === 200) {
        this.agentUsersList = data.data

        // 所有座席資訊(KV值)
        this.agentUsersList.forEach((user) => {
          this.agentUsersMap.set(user.agentId, user)
        })
      }
      return this.agentUsersList
    },
    notificationAction (msg, image) { // 通知
      const { isSupported, show, close, notification } = useWebNotification()
      if (isSupported) {
        show({
          title: this.userInfoDetails.tenant.receptionText,
          body: msg,
          icon: this.domain + image
        })
      } else {
        ElMessage.warning({ message: 'Web Notification is not supported in this browser.' })
      }
    },
    acceptTransferConv (data) { // 客服是否接收轉接 dlg
      // 去重
      if (this.transferIds.has(data.conversationId)) { return }

      // 取得頭像
      const agentName = this.agentUsersMap.get(data.currentAgentId).username

      // 建立定時任務
      let expireTime = data.promptExpireSeconds
      const timer = setInterval(() => {
        expireTime--
        if (expireTime <= 0) {
          clearInterval(timer)

          const closeIndex = this.transferList.findIndex(d => d.conversationId === data.conversationId)
          refuseTransferFn(data.conversationId, this.closeTransferDlg(closeIndex, data.conversationId))
        }
      }, 1000)

      // 更新到array
      this.transferIds.add(data.conversationId)
      this.transferList.push({
        ...data,
        currentAgentName: agentName,
        visible: true, // 彈窗是否顯示
        timer
      })
    },
    closeTransferDlg (index, convId) { // 關閉轉接彈窗
      // 銷毀定時任務
      const dialog = this.transferList[index]
      if (dialog?.timer) {
        clearInterval(dialog.timer)
      }

      this.transferList.splice(index, 1)
      this.transferIds.delete(convId)
    }
  }
  // persist: {  // 全局狀態管理永久化
  //   enabled: true,
  //   strategies: [
  //     {
  //       key: 'userInfo',
  //       storage: process.client ? localStorage : null
  //     }
  //   ]
  // }
})
