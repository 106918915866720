import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/message'
const modules = '/conversations'
const path = base + modules

// request(REQ) Api  --------------------------------
const getConvListREQ = path + '/list' // 獲取個人，在線會話列表
const getQueueListREQ = path + '/list/queue' // 獲取排隊列表
const getFinishedListREQ = path + '/list/finished' // 獲取已結束會話列表
const getCustomerConvListREQ = path + '/list/customer' // 獲取訪客歷史會話列表
const getConvOnlineInfoREQ = path + '/get/online' // 獲取在線會話 (含會話信息、渠道信息、客戶資訊)
const getConvDetailsREQ = path + '/get/details' // 獲取會話詳情 (含會話信息、客戶資訊、客戶歷史會話)
const getConvInfoREQ = path + '/get/info' // 獲取會話信息
const getConvMsgREQ = path + '/messages/list' // 獲取會話歷史訊息
const getConvMsgWithHistoryREQ = path + '/messages/list/withHistory' // 獲取會話歷史訊息 含訪客歷史紀錄
const finishConvREQ = path + '/finish' // 座席結束對話
const finishQueueREQ = path + '/queue/finish' // 結束排隊會話
const finishConvWithBlockREQ = path + '/finishWithBlock' // 提交黑名單並結束對話
const finishQueueWithBlockREQ = path + '/queue/finishWithBlock' // 提交黑名單並結束排隊
const inviteSatisfactionREQ = path + '/invite/rating' // 邀請訪客評價
const addConvsationTagsREQ = path + '/add/tags' // 添加對話標籤
const readConvREQ = path + '/readConv' // 已讀會話
const connectConvREQ = path + '/connect' // 接入會話
const connectQueueREQ = path + '/queue/connect' // 接入排隊
const assignConvREQ = path + '/assign' // 指派會話
const transferConvREQ = path + '/transfer' // 轉接會話
const agreeTransferREQ = path + '/transfer/agree' // 同意轉接
const refuseTransferREQ = path + '/transfer/refuse' // 拒絕轉接
const syncCustomerInfoREQ = path + '/sync/customer' // 同步客戶資訊
const getCollaborationListREQ = path + '/collaboration/list' // 獲取協作列表
const addCollaborationREQ = path + '/collaboration/add' // 新增協作
const getAgentUploadUrl = path + '/agent/upload' // 獲取坐席上傳鏈接

// request(REQ) Function  ---------------------------

/**
 * 獲取個人，在線會話列表
 * @param {String} params 參數 [mode 訂閱模式(agent 指定客服、all 所有客服)、
 *                              agentIds 客服id列表、
 *                              status 狀態 (已分配 assigned、未分配 unassigned、未填status : 個人已分配 + 全部未分配)]
 * @param {Object} callback 回調函數
 * @returns 在線會話列表
 */
export const getConvListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvListREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取排隊列表
 * @param {Object} params 參數 (分頁pageNum、pageSize)
 * @param {Object} callback 回調函數
 * @returns 排隊列表
 */
export const getQueueListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getQueueListREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取已結束列表
 * @param {Object} params 參數 (分頁pageNum、pageSize)
 * @param {Object} callback 回調函數
 * @returns 排隊列表
 */
export const getFinishedListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getFinishedListREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取訪客歷史會話列表
 * @param {Object} params 參數 (訪客customerId、分頁pageNum、pageSize)
 * @param {Object} callback 回調函數
 * @returns 訪客歷史會話列表
 */
export const getCustomerConvListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getCustomerConvListREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取在線會話 (含會話信息、渠道信息、客戶資訊)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getConvOnlineFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvOnlineInfoREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話詳情 (含會話信息、客戶資訊、客戶歷史會話)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 取得此訪客所有對話紀錄+當筆資訊
 */
export const getConvDetailsFn = (pamrms, callback) => { // 取得此訪客所有對話紀錄+當筆資訊
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvDetailsREQ, pamrms)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話信息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 取得當筆資訊+訊息
 */
export const getConvInfoFn = (pamrams, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvInfoREQ, pamrams)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話歷史訊息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getConvMsgFn = (pamrams, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvMsgREQ, pamrams)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話歷史訊息 含訪客歷史紀錄
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getConvMsgWithHistoryFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConvMsgWithHistoryREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 座席結束對話
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const finishConvFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + finishConvREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'PUT',
    onResponse: callback
  })
}

/**
 * 結束排隊會話
 * @param {String} customerId 訪客id
 * @param {String} channelId 渠道id
 * @param {Object} callback
 * @returns
 */
export const finishQueueFn = (customerId, channelId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + finishQueueREQ + `?customerId=${customerId}&channelId=${channelId}`
  return useCustomFetch(api, {
    method: 'PUT',
    onResponse: callback
  })
}

/**
 * 提交黑名單並結束對話
 * @param {String} convId 會話id
 * @param {Object} formData 表單提交 (黑名單參數)
 * @param {Object} callback 回調函數
 * @returns
 */
export const finishConvWithBlockFn = (convId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + finishConvWithBlockREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 提交黑名單並結束排隊
 * @param {String} customerId 訪客id
 * @param {String} channelId 渠道id
 * @param {Object} formData 表單提交 (黑名單參數)
 * @param {Object} callback 回調函數
 * @returns
 */
export const finishQueueWithBlockFn = (customerId, channelId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + finishQueueWithBlockREQ + `?customerId=${customerId}&channelId=${channelId}`
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 邀請訪客評價
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const inviteSatisfactionFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + inviteSatisfactionREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 新增會話標籤至會話
 * @param {String} convId 會話id
 * @param {Array<Long>} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const addConversationTagsFn = (convId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + addConvsationTagsREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 已讀會話
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const readConvFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + readConvREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 接入對話
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const connectConvFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + connectConvREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 接入排隊
 * @param {String} customerId 訪客id
 * @param {String} channelId 渠道id
 * @param {Object} callback
 * @returns
 */
export const connectQueueFn = (customerId, channelId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + connectQueueREQ + `?customerId=${customerId}&channelId=${channelId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 指派會話
 * @param {Object} params 參數 (會話Id : convId, 目標座席Id : userId)
 * @param {*} callback 回調函數
 * @returns
 */
export const assignConvFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(assignConvREQ, params)
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 轉接會話
 * @param {String} convId 會話id
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const transferConvFn = (convId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + transferConvREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 同意轉接
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const agreeTransferFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + agreeTransferREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 拒絕轉接
 * @param {String} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const refuseTransferFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + refuseTransferREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 同步客戶資訊
 * @param {string} convId 會話id
 * @param {Object} callback 回調函數
 * @returns
 */
export const syncCustomerInfoFn = (convId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + syncCustomerInfoREQ + `?convId=${convId}`
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 獲取協作列表
 * @param {Object} callback 回調函數
 * @returns 協作列表
 */
export const getCollaborationListFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + getCollaborationListREQ
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增協作
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addCollaborationFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + addCollaborationREQ
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * (客服)獲取上傳鏈接
 * @param {Object} formData
 * @param {Object} callback
 * @returns 資料
 */
export const getAgentUploadUrlFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getAgentUploadUrl, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}
